import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {User} from '../model/user/user.model';
import {BehaviorSubject, firstValueFrom, Subject} from 'rxjs';
import {UserWebservice} from './webservices/user.webservice';
import {Year} from '../model/user/year.model';
import {AlertService} from './alert.service';
import {Address} from '../model/event/place/address.model';
import {RelationUserEnum} from '../model/enums/relation-user.enum';
import {RegisteredEvent} from '../model/event/registered-event.model';
import {SimpleEventListDTO} from '../model/dto/simple-event-list.dto';
import {YearWebservice} from './webservices/year.webservice';
import {globalAppParam} from 'src/environments/environment';
import {RelationUser} from '../model/user/relation-user.model';
import {SchoolLevel} from '../model/user/school-level.model';
import {Parcours} from '../model/event/parcours/parcours.model';
import {HighestLevelEnum} from '../model/enums/highest-level.enum';
import {EligibilityFseEnum} from '../model/enums/eligibility-fse.enum';
import {Destroyed} from '../components/shared/directives/destroyed.directive';
import {DialogService} from './dialog.service';
import {YoungWebservice} from './webservices/young.webservice';
import {isNotNullOrUndefined} from '../utils/utils.static';
import {MatFileInput} from '../components/shared/form-parts/mat-file-input/model/mat-file-input.model';
import {ProgramEnum} from '../model/enums/program.enum';
import {GeneralTabForm} from '../model/creation/general-tab.form';
import {RelativeUserForm} from '../model/creation/relative-user.form';
import {RelationUserForm} from '../model/creation/relation-user.form';
import {SdlvTabForm} from '../model/creation/sdlv-tab.form';
import {JobTabForm} from '../model/creation/job-tab.form';
import {ToastrService} from 'ngx-toastr';
import {
  FirstPageErrors,
  YoungCreationErrorHelper
} from '../utils/helpers/young-creation-error.helper';
import {YoungCreationInitHelper} from '../utils/helpers/young-creation-init.helper';

@Injectable({
  providedIn: 'root'
})
export class YoungCreationDataService extends Destroyed {
  schoolLevels: SchoolLevel[];
  // validation du numero de telephone different entre tous les profils a creer (responsable / enfant)
  public _phoneYoung: string;
  public _phoneResp1: string;
  public _phoneResp2: string;
  public _isValidPhoneForAll: boolean;

  public _mailYoung: string;
  public _mailResp1: string;
  public _mailResp2: string;
  public _isValidMailForAll: boolean;

  public certificatMedicalYoung: Year;

  isRespSameAddress = true;
  isUserEmailAlreadyUse = false;
  isUserPhoneAlreadyUse = false;
  isResp1EmailAlreadyUse = false;
  isResp1PhoneAlreadyUse = false;
  isResp2EmailAlreadyUse = false;
  isResp2PhoneAlreadyUse = false;
  hasResp2 = false;

  private readonly user = new Subject<void>();
  public userObservable = this.user.asObservable();
  private readonly dataCurrentUser = new BehaviorSubject(null);
  public currentUserMessager = this.dataCurrentUser.asObservable();
  private readonly dataCurrentYear = new BehaviorSubject(null);
  public currentYearMessager = this.dataCurrentYear.asObservable();
  private readonly isFormSubmitted = new BehaviorSubject(null);
  public isFormSubmittedMessenger = this.isFormSubmitted.asObservable();
  private readonly dataDefaultAddress = new BehaviorSubject(null);
  public defaultAddressMessenger = this.dataDefaultAddress.asObservable();
  private readonly inputRegisterEvent = new BehaviorSubject({});
  public currentInputRegisterEvent = this.inputRegisterEvent.asObservable();
  private isValidInputForRegister = false;
  private readonly _registerEvent: Map<number, Event[]> = new Map<number, Event[]>();
  private readonly _unregisterEvent: Map<number, Event[]> = new Map<number, Event[]>();

  private readonly isEligibleFse = new BehaviorSubject(EligibilityFseEnum.NOT_ELIGIBLE);
  public currentIsEligibleFse = this.isEligibleFse.asObservable();

  private readonly programList = new BehaviorSubject([]);
  public currentProgramList = this.programList.asObservable();

  private readonly _latestParcours = new BehaviorSubject(null);
  public latestParcours = this._latestParcours.asObservable();
  /**
   *
   * New Form save method
   *
   *
   */
  private sendSmsInscriptionJob: boolean = false;
  private readonly _generaleTabs: GeneralTabForm = {};
  private readonly _sdlvTabs: SdlvTabForm = {};
  private readonly _jobTabs: JobTabForm = {};

  constructor(
    private readonly userWebservice: UserWebservice,
    private readonly alertService: AlertService,
    private readonly toastrService: ToastrService,
    private readonly yearWebservice: YearWebservice,
    private readonly dialogService: DialogService,
    private readonly youngWebservice: YoungWebservice
  ) {
    super();
  }

  private _testUserInfoFormGroup: UntypedFormGroup;

  get testUserInfoFormGroup() {
    return this._testUserInfoFormGroup;
  }

  set testUserInfoFormGroup(form: UntypedFormGroup) {
    this._testUserInfoFormGroup = form;

    const val = form.value;
    this._generaleTabs.lastName = val.lastName;
    this._generaleTabs.firstName = val.firstName;
    this._generaleTabs.birthday = new Date(val.birthday);

    this.updateIsValidInputForRegisterEvent();
  }

  get generalTab() {
    return this._generaleTabs;
  }

  private _infoUserGeneralFormGroup: UntypedFormGroup;

  get infoUserGeneralFormGroup() {
    return this._infoUserGeneralFormGroup;
  }

  set infoUserGeneralFormGroup(form: UntypedFormGroup) {
    this._infoUserGeneralFormGroup = form;

    const val = form.value;
    this._generaleTabs.sex = val.sex;
    this._generaleTabs.email = val.email;
    this._generaleTabs.phone = val.phone;
    this._generaleTabs.phoneCode = val.phoneCode;

    this._generaleTabs.address = val.address ? val.address.address1 : undefined;
    this._generaleTabs.postCode = val.address ? val.address.zipCode : undefined;
    this._generaleTabs.city = val.address ? val.address.city : undefined;

    this.updateIsValidInputForRegisterEvent();
  }

  private _infoYoungGeneralFormGroup: UntypedFormGroup;

  get infoYoungGeneralFormGroup() {
    return this._infoYoungGeneralFormGroup;
  }

  set infoYoungGeneralFormGroup(form: UntypedFormGroup) {
    this._infoYoungGeneralFormGroup = form;

    const val = form.value;
    this._generaleTabs.numberBrother = val.numberBrother;
    this._generaleTabs.numberSister = val.numberSister;
    this._generaleTabs.handicap = val.handicap;
    this._generaleTabs.medicalObservation = val.medicalObservation;
    this._generaleTabs.childs = val.childs;
    this._generaleTabs.drivingLicence = val.drivingLicence;
  }

  private _infoMedicalDocFormGroup: UntypedFormGroup;

  get infoMedicalDocFormGroup() {
    return this._infoMedicalDocFormGroup;
  }

  set infoMedicalDocFormGroup(form: UntypedFormGroup) {
    this._infoMedicalDocFormGroup = form;

    const val = form.value;
    const limitCertifDate = new Date();
    limitCertifDate.setFullYear(limitCertifDate.getFullYear() - 3);

    const fileFormValue: MatFileInput = val.certifFile;

    if (new Date(val.dateCertif) > limitCertifDate) {
      this._sdlvTabs.validatedDateMedicalCertificate = val.dateCertif;
      this._sdlvTabs.validatedMedicalCertificate = true;

      if (fileFormValue) {
        this._sdlvTabs.certifFile = fileFormValue.files[0];
      } else {
        this._sdlvTabs.certifFile = null;
      }
    } else {
      this._sdlvTabs.validatedDateMedicalCertificate = null;
      this._sdlvTabs.validatedMedicalCertificate = false;
      this._sdlvTabs.certifFile = null;
    }

    this._sdlvTabs.parentalAuthorizationDate = val.dateAuth;
    this._sdlvTabs.hasParentalAuthorization = val.authorization;

    this._sdlvTabs.acceptedRGPD = val.rgpd;
    this._sdlvTabs.acceptedRGPDDate = val.rgpdDate;

    this._sdlvTabs.imageRightAutorization = val.imageRigth;
    this._sdlvTabs.imageRightAutorizationDate = val.imageRigthDate;

    this._sdlvTabs.contactAccepted = val.contactRigth;
    this._sdlvTabs.contactAcceptedDate = val.contactRigthDate;
  }

  private _infoRespLegalFormGroup: UntypedFormGroup;

  get infoRespLegalFormGroup() {
    return this._infoRespLegalFormGroup;
  }

  // tous les forms des onglets

  set infoRespLegalFormGroup(form: UntypedFormGroup) {
    this._infoRespLegalFormGroup = form;

    const val = form.value;

    if (val?.families?.length > 0) {
      this.handleRespForms(val);
    } else {
      this._generaleTabs.resp1 = undefined;
      this._generaleTabs.resp2 = undefined;
    }
  }

  private _sdlvInfoFormGroup: UntypedFormGroup;

  get sdlvInfoFormGroup() {
    return this._sdlvInfoFormGroup;
  }

  set sdlvInfoFormGroup(form: UntypedFormGroup) {
    this._sdlvInfoFormGroup = form;

    const val = form.value;
    this._sdlvTabs.height = val.height;
    this._sdlvTabs.weight = val.weight;
    this._sdlvTabs.comeAlone = val.comeAlone;
  }

  private _placeFormGroup: UntypedFormGroup;

  get placeFormGroup() {
    return this._placeFormGroup;
  }

  set placeFormGroup(form: UntypedFormGroup) {
    this._placeFormGroup = form;
    this.updateIsValidInputForRegisterEvent();

    const val = form.value;
    this._generaleTabs.place = val.place;
    this._generaleTabs.nameCenter = val.nameCenter;
  }

  get sdlvTabs() {
    return this._sdlvTabs;
  }

  private _jobInfoYoungFormGroup: UntypedFormGroup;

  get jobInfoYoungFormGroup() {
    return this._jobInfoYoungFormGroup;
  }

  set jobInfoYoungFormGroup(form: UntypedFormGroup) {
    this._jobInfoYoungFormGroup = form;

    const val = form.value;
    this.jobTabs.idRi = val.idRi;
    this.jobTabs.idOrigin = val.idOrigin;
    this.jobTabs.hasDrivingLicence = val.hasDrivingLicence;
    this.jobTabs.drivingLicenceDate = val.drivingLicenceDate;
    this.jobTabs.mobility = val.mobility;
    this.jobTabs.meansTransport = val.meansTransport;
    this.jobTabs.nir = val.nir;
    this.jobTabs.nationality = val.nationality;
  }

  private _jobInfoJobFormGroup: UntypedFormGroup;

  get jobInfoJobFormGroup() {
    return this._jobInfoJobFormGroup;
  }

  set jobInfoJobFormGroup(form: UntypedFormGroup) {
    this._jobInfoJobFormGroup = form;

    const val = form.value;
    this.jobTabs.subProgram = val.subProgram;
    this.jobTabs.subProgramDate = val.subProgramDate;
    this.jobTabs.booster = val.booster;
    this.jobTabs.scolarshipStatus = val.scolarshipStatus;
    this.jobTabs.endSchoolDate = val.endSchoolDate;
    this.jobTabs.highestLevel = val.highestLevel;
    this.jobTabs.highestLevelDate = val.highestLevelDate;
    this.jobTabs.registeredPoleEmploi = val.registeredPoleEmploi;
    this.jobTabs.registeredPoleEmploiDate = val.registeredPoleEmploiDate;
    this.jobTabs.registeredMissionLocale = val.registeredMissionLocale;
    this.jobTabs.registeredMissionLocaleDate = val.registeredMissionLocaleDate;
    this.jobTabs.registeredCapEmploi = val.registeredCapEmploi;
    this.jobTabs.registeredCapEmploiDate = val.registeredCapEmploiDate;
    this.jobTabs.isInternationalProtection = val.isInternationalProtection;
    this.jobTabs.hasIsolatedParent = val.hasIsolatedParent;
    this.jobTabs.jobbeurFinancingMechanism = val.financingMechanisms;
    this.jobTabs.isBenefObligEmploi = val.isBenefObligEmploi;
    this.jobTabs.isAllocMinSociaux = val.isAllocMinSociaux;
    this.jobTabs.isDemandeurAsile = val.isDemandeurAsile;
    this.jobTabs.isSignatContratIntegRepublic = val.isSignatContratIntegRepublic;
  }

  private _jobInfoBesoinFormGroup: UntypedFormGroup;

  get jobInfoBesoinFormGroup() {
    return this._jobInfoBesoinFormGroup;
  }

  set jobInfoBesoinFormGroup(form: UntypedFormGroup) {
    this._jobInfoBesoinFormGroup = form;

    const val = form.value;

    this.jobTabs.hasJobbeurNeed = val.hasJobbeurNeed;
    this.jobTabs.jobbeurAccompaniment = val.jobbeurAccompaniment;
    this.jobTabs.jobbeurProject = val.jobbeurProject;
    this.jobTabs.jobbeurTravel = val.jobbeurTravel;
    this.jobTabs.pillars = val.pillars;
    this.jobTabs.interestFields = val.interestFields;
    this.jobTabs.domainMetiers = val.domainMetiers;
    this.jobTabs.professionalProject = val.professionalProject;
    this.jobTabs.sponsorWish = val.sponsorWish;
    this.jobTabs.sponsorSex = val.sponsorSex;
  }

  get jobTabs() {
    return this._jobTabs;
  }

  setRegisterEvent(event: Event[], key: number) {
    this._registerEvent.set(key, event);
    this.setAction();
  }

  setUnregisterEvent(event: Event[], key: number) {
    this._unregisterEvent.set(key, event);
    this.setAction();
  }

  resetRegisterEvent() {
    this._registerEvent.clear();
    this._unregisterEvent.clear();
    this.setAction();
  }

  removeRegisterEvent(key: number) {
    this.setUnregisterEvent(this.getRegisterEvent(key), key);
    this._registerEvent.delete(key);
    this.setAction();
  }

  removeUnregisterEvent(key: number) {
    this._unregisterEvent.delete(key);
    this.setAction();
  }

  getRegisterEvent(key: number) {
    return this._registerEvent.get(key);
  }

  setAction() {
    const flattenedArray = this.flattenMap();
    const flattenedArrayUnsubscribed = this.flattenMapUnsubscribed();

    this.sdlvTabs.activity = flattenedArray;
    if (
      (!this.sdlvTabs.activity || this.sdlvTabs.activity.length === 0) &&
      this.getCurrentUser()?.listOfYears?.length > 0 &&
      this.getCurrentUser().listOfYears[0]
    ) {
      this.sdlvTabs.activity = this.getCurrentUser().listOfYears[0].listOfRegisteredEvent;
    }

    this.sdlvTabs.activityUnsubscribed = flattenedArrayUnsubscribed;
  }

  changeCurrentUser(user: User) {
    this.dataCurrentUser.next(user);
    if (user) {
      this.initYears();
    }
  }

  getCurrentUser(): User {
    return this.dataCurrentUser.getValue();
  }

  changeCurrentYear(year: Year) {
    this.dataCurrentYear.next(year);
  }

  changeIsCheckerEnable(bool: boolean) {
    this.isFormSubmitted.next(bool);
  }

  changeDefaultAddress(address: Address) {
    this.dataDefaultAddress.next(address);
  }

  getInvalidControls(formGroup: UntypedFormGroup) {
    const invalid = [];
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  isFirstPageOk() {
    return (
      this.checkAtLeastOne() &&
      this.infoUserGeneralFormGroup.valid &&
      this.infoYoungGeneralFormGroup.valid &&
      this.placeFormGroup.valid &&
      this.isInfoRespValid()
    );
  }

  isInfoRespValid() {
    return (
      (!this.isMajor() &&
        this.infoRespLegalFormGroup.valid &&
        this.infoRespLegalFormGroup.controls?.families?.value?.length > 0 &&
        this.infoRespLegalFormGroup.value?.families[0]?.relative?.id !== null) ||
      (this.isMajor() &&
        (this.infoRespLegalFormGroup.valid || this.infoRespLegalFormGroup.status === 'DISABLED'))
    );
  }

  getFirstPageErrors() {
    const firstPageErrors : FirstPageErrors = {
      programList: this.programList,
      generalTab: this.generalTab,
      placeFormGroup: this.placeFormGroup,
      infoUserGeneralFormGroup: this.infoUserGeneralFormGroup,
      infoYoungGeneralFormGroup: this.infoYoungGeneralFormGroup,
      testUserInfoFormGroup: this.testUserInfoFormGroup,
      infoRespLegalFormGroup: this.infoRespLegalFormGroup,
      checkAtLeastOne: this.checkAtLeastOne(),
      isRespSameAddress: this.isRespSameAddress
    };
    return YoungCreationErrorHelper.getFirstPageErrors(firstPageErrors);
  }

  saveInDb(): Promise<number> {
    return new Promise<number>(async (resolve, reject) => {
      let valid = true;

      this.changeIsCheckerEnable(true);

      // check que le young est valide, pour le tel et email (check pas avec des Validators)
      if (!this.checkAtLeastOne()) {
        valid = false;
      }

      const errors = YoungCreationErrorHelper.testAll(
        this.generalTab,
        this.sdlvTabs,
        this.jobTabs,
        this.schoolLevels,
        this.programList,
        this.isRespSameAddress
      );
      if (errors?.length > 0) {
        this.dialogService.errorInscription('Informations manquantes', errors).then();
        return reject();
      } else {
        if (this.areFormGroupsValid(valid)) {
          // check que le reste du formulaire est valide (avec des Validators)

          this.clearAlert();
          try {
            const user: User = YoungCreationInitHelper.setUser(
              this.testUserInfoFormGroup,
              this.infoUserGeneralFormGroup,
              this.schoolLevels,
              this.dataCurrentUser.value
            );

            const relationUserRI = this.recuperationRelationRI(user);

            YoungCreationInitHelper.setFamily(
              user,
              this.infoRespLegalFormGroup.value.families,
              this.isRespSameAddress
            );

            this.addRelationUserRI(user, relationUserRI);

            YoungCreationInitHelper.setYear(user, this.dataCurrentYear.value, this.placeFormGroup);

            this.setProgramInformations(user);

            // Evite les circular error (bloquantes sur l'enregistrement)

            if (!user) {
              return reject();
            }
            this.fixCircularDependenciesOnRegisterEvent(user);

            // Recupere la liste des families
            if (this.checkJeuneMineurSansResponsable(user)) {
              this.onErrorAlert(
                'Un jeune mineur doit obligatoirement avoir au moins un responsable !'
              );
              return reject();
            }

            await firstValueFrom(
              this.userWebservice.postRegisterUser(user).pipe(this.untilDestroyed())
            )
              .then((content: User) => {
                this.toastrService.success('Le jeune a été correctement enregistré');
                this.saveCertificat(content);
                this.firstJobInscriptionToSendSMS(content);
                this.saveQuestionnaireMedical(content);
                resolve(content.id);
              })
              .catch(() => {
                this.toastrService.error(
                  'Une erreur est survenue lors de la création/modification du jeune'
                );
                return reject();
              })
              .finally(() => {
                this.resetRegisterEvent();
              });
          } catch (e) {
            this.checkErrorsAfterCatch(e);
            return reject();
          }
        } else {
          return reject();
        }
      }
      return reject();
    });
  }

  saveQuestionnaireMedical(user: User) {
    if (
      this.programList.getValue().some((item) => item === ProgramEnum.SPORTDANSLAVILLE) &&
      user.listOfYears.length > 0 &&
      this.certificatMedicalYoung
    ) {
      this.certificatMedicalYoung.idUser = user.id;
      this.certificatMedicalYoung.id = user.listOfYears[0].id;

      this.yearWebservice
        .postUpdateYearSport(this.certificatMedicalYoung)
        .pipe(this.untilDestroyed())
        .subscribe((data: Year) => {
          this.changeCurrentYear(data);
          this.certificatMedicalYoung = data;
        });
    }
  }

  saveCertif(idUser: number) {
    if (this._sdlvTabs && this._sdlvTabs.certifFile) {
      const formData = new FormData();
      formData.append('file', this._sdlvTabs.certifFile);
      this.yearWebservice
        .postUpdateCertificat(idUser, formData)
        .pipe(this.untilDestroyed())
        .subscribe(() => {
          this.onSuccessAlert('Le document a bien été sauvegardé');
        });
    }
  }

  isMajor() {
    return User.formatAge(this.testUserInfoFormGroup.controls.birthday.value) >= 18;
  }

  checkAtLeastOne(): boolean {
    // Email et téléphone doivent être entrés
    const valid = this.checkPhoneAndMailForMajor();

    const validResp1 = this._generaleTabs.resp1
      ? !!(this._phoneResp1 && !this.isResp1PhoneAlreadyUse)
      : true;
    const validResp2 = this._generaleTabs.resp2
      ? !!((this._phoneResp2 && !this.isResp2PhoneAlreadyUse) || !this._phoneResp2)
      : true;
    return valid && validResp1 && validResp2;
  }

  // alert
  onSuccessAlert(message: string) {
    this.clearAlert();
    this.alertService.success(message);
  }

  onErrorAlert(message: string) {
    this.clearAlert();
    this.alertService.error(message);
  }

  clearAlert() {
    this.alertService.clear();
  }

  // Initialisation du years + si modif selection du years valide, si pas valide : création d'un years
  initYears() {
    let year = new Year();

    if (this.dataCurrentUser.value) {
      if (
        !this.dataCurrentUser.value.listOfYears ||
        this.dataCurrentUser.value.listOfYears.length === 0
      ) {
        this.initNewYears(year);
      } else {
        const yearMax: Year = this.dataCurrentUser.value.listOfYears[0];

        if (
          !yearMax ||
          new Date(yearMax.dateEnd) < new Date() ||
          (globalAppParam.limitDateInscription < new Date() &&
            new Date(yearMax.dateStart) < globalAppParam.limitDateInscription)
        ) {
          this.initNewYears(year);
        } else {
          year = yearMax;
        }
      }

      if (year.listOfRegisteredProgram) {
        this.programList.next(year.listOfRegisteredProgram.map((item) => item.idProgram));
      }
      this.changeCurrentYear(year);
    }
  }

  initNewYears(year: Year) {
    // Initialise un nouveau years
    let yearStartNumber,
      yearEndNumber = 0;

    if (new Date() >= globalAppParam.limitDateInscription) {
      yearStartNumber = new Date().getFullYear();
      yearEndNumber = new Date().getFullYear() + 1;
    } else {
      yearStartNumber = new Date().getFullYear() - 1;
      yearEndNumber = new Date().getFullYear();
    }

    // Initialise la date de fin août à début septembre de l'année suivante
    year.dateStart = new Date(yearStartNumber, 8, 1);
    year.dateEnd = new Date(yearEndNumber, 7, 31);
    year.id = 0;
    year.idUser = this.dataCurrentUser.value.id;

    if (this.dataCurrentUser.value.id) {
      this.youngWebservice
        .getLatestParcours(this.dataCurrentUser.value.id)
        .pipe(this.untilDestroyed())
        .subscribe((latest: Parcours) => {
          if (isNotNullOrUndefined(latest)) {
            this.changeLatestParcours(latest);
          }
        });
    }
  }

  // Si le numero est valide, return true -> si existant sur le resp 1 ou 2 return false
  updatePhoneYoung(phone: string): boolean {
    if (phone && phone !== '') {
      this._isValidPhoneForAll = phone !== this._phoneResp1 && phone !== this._phoneResp2;
      this._phoneYoung = phone;
      return this._isValidPhoneForAll;
    } else {
      this._phoneYoung = phone;
      this._isValidPhoneForAll = true;
      return this._isValidPhoneForAll; // si vide OK
    }
  }

  updateMailYoung(mail: string): boolean {
    if (mail && mail !== '') {
      this._isValidMailForAll = mail !== this._mailResp1 && mail !== this._mailResp2;
      this._mailYoung = mail;
      return this._isValidMailForAll;
    } else {
      this._mailYoung = mail;
      this._isValidMailForAll = true;
      return this._isValidMailForAll; // si vide OK
    }
  }

  updateMailResp1(mail: string): boolean {
    if (mail && mail !== '') {
      this._isValidMailForAll = mail !== this._mailYoung && mail !== this._mailResp2;
      this._mailResp1 = mail;
      return this._isValidMailForAll;
    } else {
      this._mailResp1 = mail;
      this._isValidMailForAll = true;
      return this._isValidMailForAll;
    }
  }

  updateMailResp2(mail: string): boolean {
    if (mail && mail !== '') {
      this._isValidMailForAll = mail !== this._mailYoung && mail !== this._mailResp1;
      this._mailResp2 = mail;
      return this._isValidMailForAll;
    } else {
      this._mailResp2 = mail;
      this._isValidMailForAll = true;
      return this._isValidMailForAll;
    }
  }

  updatePhoneResp1(phone: string): boolean {
    if (phone && phone !== '') {
      this._isValidPhoneForAll = phone !== this._phoneYoung && phone !== this._phoneResp2;
      this._phoneResp1 = phone;
      return this._isValidPhoneForAll;
    } else {
      this._phoneResp1 = phone;
      this._isValidPhoneForAll = true;
      return this._isValidPhoneForAll;
    }
  }

  updatePhoneResp2(phone: string): boolean {
    if (phone && phone !== '') {
      this._isValidPhoneForAll = phone !== this._phoneYoung && phone !== this._phoneResp1;
      this._phoneResp2 = phone;
      return this._isValidPhoneForAll;
    } else {
      this._phoneResp2 = phone;
      this._isValidPhoneForAll = true;
      return this._isValidPhoneForAll;
    }
  }

  // Penser a vider les numeros dans le ngdestroy
  clearAllPhone() {
    this._phoneYoung = null;
    this._phoneResp1 = null;
    this._phoneResp2 = null;
  }

  getAgeYoung() {
    return this._generaleTabs.birthday;
  }

  changeIsEligibleFse(value?: EligibilityFseEnum) {
    if (isNotNullOrUndefined(value)) {
      this.isEligibleFse.next(value);
    } else {
      if (
        this.generalTab.birthday &&
        isNotNullOrUndefined(this.jobTabs.highestLevel) &&
        this.generalTab.postCode
      ) {
        if (
          User.formatAge(this.generalTab.birthday) < 26 &&
          this.jobTabs.highestLevel < HighestLevelEnum.BAC_2 &&
          this.checkAddressInAURA()
        ) {
          this.isEligibleFse.next(EligibilityFseEnum.POTENTIALLY_ELIGIBLE);
        } else {
          this.isEligibleFse.next(EligibilityFseEnum.NOT_ELIGIBLE);
        }
      } else {
        this.isEligibleFse.next(EligibilityFseEnum.NOT_ELIGIBLE);
      }
    }
  }

  changeProgramList(items: unknown[]) {
    this.programList.next(items);
  }

  changeUser() {
    this.user.next();
  }

  changeLatestParcours(parcours: Parcours) {
    this._latestParcours.next(parcours);
  }

  getIsEligibleFSE() {
    return this.isEligibleFse.getValue();
  }

  private handleRespForms(val) {
    this._generaleTabs.resp1 = null;
    this._generaleTabs.resp2 = null;
    if (val.families.length > 0) {
      const tutor1 = val.families.find((fam) => fam.relationUserEnum === RelationUserEnum.TUTOR1);
      if (tutor1) {
        const user = tutor1.relative ?? ({} as RelativeUserForm);
        user.address = user.address || new Address();
        this.setGeneralTabsResp1(tutor1, user);
      }

      const tutor2 = val.families.find((fam) => fam.relationUserEnum === RelationUserEnum.TUTOR2);
      if (tutor2) {
        const user = tutor2.relative ?? ({} as RelativeUserForm);
        user.address = user.address || new Address();
        this.setGeneralTabsResp2(tutor2, user);
      }
    }
  }

  private checkErrorsAfterCatch(e) {
    const errorsAfterCatch = YoungCreationErrorHelper.testAll(
      this.generalTab,
      this.sdlvTabs,
      this.jobTabs,
      this.schoolLevels,
      this.programList,
      this.isRespSameAddress
    );
    if (errorsAfterCatch?.length > 0) {
      this.dialogService.errorInscription('Informations manquantes', errorsAfterCatch);
    } else {
      this.onErrorAlert('Une erreur est survenue : ' + e.message);
    }
  }

  private addRelationUserRI(user: User, relationUserRI: RelationUser[]) {
    if (user.listOfUser1 && relationUserRI) {
      user.listOfUser1.push(...relationUserRI);
    }
  }

  private checkPhoneAndMailForMajor() {
    if (this.isMajor()) {
      return (
        this.infoUserGeneralFormGroup.controls.email.value &&
        this.infoUserGeneralFormGroup.controls.phone.value &&
        !this.isUserEmailAlreadyUse &&
        !this.isUserPhoneAlreadyUse
      );
    } else {
      return true;
    }
  }

  private areFormGroupsValid(valid: boolean) {
    return (
      valid &&
      this.infoUserGeneralFormGroup.valid &&
      this.infoYoungGeneralFormGroup.valid &&
      this.placeFormGroup.valid &&
      (this.infoRespLegalFormGroup.valid || this.infoRespLegalFormGroup.status === 'DISABLED')
    );
  }

  private setProgramInformations(user: User) {
    if (this.programList.getValue() && this.programList.getValue().length > 0) {
      YoungCreationInitHelper.setSdlvInformations(
        user,
        this.programList,
        this.sdlvInfoFormGroup,
        this.sdlvTabs,
        this._registerEvent,
        this._unregisterEvent
      );

      if (
        this.programList.getValue().some((item) => item === ProgramEnum.JOBDANSLAVILLE) &&
        !user.listOfYears[0]?.listOfRegisteredProgram?.some(
          (item) => item.idProgram === ProgramEnum.JOBDANSLAVILLE
        )
      ) {
        this.sendSmsInscriptionJob = true;
      }

      YoungCreationInitHelper.setJdlvInformations(
        user,
        this.programList.getValue(),
        this.jobInfoYoungFormGroup,
        this.jobInfoJobFormGroup,
        this.jobInfoBesoinFormGroup,
        this.jobTabs
      );
    }
  }

  private fixCircularDependenciesOnRegisterEvent(user: User) {
    user.listOfYears.forEach((value: Year) => {
      if (value.listOfRegisteredEvent) {
        value.listOfRegisteredEvent.forEach((re: RegisteredEvent) => {
          if (re.event) {
            re.event.listOfRegisteredEvent = null;
          }
        });
      }
    });
  }

  private firstJobInscriptionToSendSMS(content: User) {
    if (this.sendSmsInscriptionJob) {
      this.userWebservice
        .postSmsValidation(content, true)
        .pipe(this.untilDestroyed())
        .subscribe(() => {
          this.sendSmsInscriptionJob = false;
        });
    }
  }

  private saveCertificat(content: User) {
    if (this.programList.getValue().some((item) => item === ProgramEnum.SPORTDANSLAVILLE)) {
      this.saveCertif(content.id);
    }
  }

  private recuperationRelationRI(user: User) {
    let relationUserRi = [];
    if (user.listOfUser1.some((item) => item.relationUserEnum === RelationUserEnum.RI)) {
      relationUserRi = user.listOfUser1.filter(
        (item) => item.relationUserEnum === RelationUserEnum.RI
      );
    }
    return relationUserRi;
  }

  private checkJeuneMineurSansResponsable(user: User) {
    return (
      User.formatAge(user.birthday) < 18 &&
      (!user.listOfUser1 ||
        user.listOfUser1.filter(
          (item) =>
            item.relationUserEnum === RelationUserEnum.TUTOR1 ||
            item.relationUserEnum === RelationUserEnum.TUTOR2
        ).length === 0)
    );
  }

  private setGeneralTabsResp2(family: RelationUserForm, user: RelativeUserForm) {
    this._generaleTabs.resp2 = {
      familyStatus: family.familySituation,
      job: family.job,
      lastName: user.lastName,
      firstName: user.firstName,
      phoneCode: user.phoneCode,
      phone: user.phone,
      email: user.email,
      address: user.address.address1,
      postCode: user.address.zipCode,
      city: user.address.city,
      sex: user.respSex2
    };
  }

  private setGeneralTabsResp1(family: RelationUserForm, user: RelativeUserForm) {
    this._generaleTabs.resp1 = {
      familyStatus: family.familySituation,
      job: family.job,
      lastName: user.lastName,
      firstName: user.firstName,
      phoneCode: user.phoneCode,
      phone: user.phone,
      email: user.email,
      address: user.address.address1,
      postCode: user.address.zipCode,
      city: user.address.city,
      sex: user.respSex1
    };
  }

  private flattenMap(): SimpleEventListDTO[] {
    let newArray = [];
    this._registerEvent.forEach((value: Event[]) => {
      newArray = newArray.concat(...value);
    });

    return newArray;
  }

  private flattenMapUnsubscribed(): SimpleEventListDTO[] {
    let newArray = [];
    this._unregisterEvent.forEach((value: Event[]) => {
      newArray = newArray.concat(...value);
    });

    return newArray;
  }

  private updateIsValidInputForRegisterEvent() {
    if (this._testUserInfoFormGroup && this._infoUserGeneralFormGroup && this._placeFormGroup) {
      const birthday = this._testUserInfoFormGroup.controls.birthday;
      const sex = this._infoUserGeneralFormGroup.controls.sex.value;
      const place = this._placeFormGroup.controls.place.value;

      this.isValidInputForRegister = birthday && birthday.valid && sex >= 0 && sex < 3 && place > 0;
    } else {
      this.isValidInputForRegister = false;
    }
    if (this.isValidInputForRegister) {
      this.inputRegisterEvent.next({
        birthday: new Date(this._testUserInfoFormGroup.controls.birthday.value),
        sex: this._infoUserGeneralFormGroup.controls.sex.value,
        place: this._placeFormGroup.controls.place.value
      });
    } else {
      this.inputRegisterEvent.next({});
    }
  }

  private checkAddressInAURA() {
    return /^(01|03|07|15|26|38|42|43|63|69|73|74).*/.test(this.generalTab.postCode);
  }
}
